<!--
 * @Author: your name
 * @Date: 2021-10-26 15:02:33
 * @LastEditTime: 2021-12-06 09:33:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-exam\src\views\list\index.vue
-->
<template>
    <div class="exam-list">
        <Nav></Nav>
        <!-- <div class="list-header">
            <div class="list-banner">
                <div class="exam-search">
                    <div class="search-header">档案中心</div>
                    <div class="search-input-modal">
                        <el-input placeholder="海量试题，输入关键词进行搜索" type="text" v-model="listQuery.examUserListSearchBase.examName" class="search-input" />
                        <el-button type="primary" @click="searchList" class="search-btn">
                            <div class="btn-text">
                                <img src="../../assets/list/search-btn.png" alt="" />
                                <span>搜索</span>
                            </div>
                        </el-button>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="list-main">
            <div class="main-header">
                <div style="display: flex">
                    <div class="content-btn" :class="[activeType == 'make' && 'select']" @click="makeExam('make')">考试档案</div>
                </div>
                <div class="file-btn" @click="goBack">
                    <span style="margin-right: 8px">返回</span>
                    <img src="../../assets/list/arrows-right.png" alt="" />
                </div>
            </div>
            <div class="main-content">
                <el-table empty-text="暂无数据" class="customer-table" :data="tableData" :height="567" stripe style="width: 100%">
                    <el-table-column header-align="center" label="考试名称" width="300">
                        <template slot-scope="scope">
                            <span style="padding-left: 32px" class="exam-name">{{ scope.row.examName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="考试类型">
                        <template slot-scope="scope">
                            <span>{{ scope.row.examTypeCodeName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="examTime" label="成绩">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gradeScoreValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="examTime" label="是否通过">
                        <template slot-scope="scope">
                            <span style="font-size: 14px; font-weight: 400; text-align: left; color: rgb(255, 0, 0); line-height: 15px">{{ scope.row.isPassValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="examTime" label="状态">
                        <template slot-scope="scope">
                            <span style="font-size: 14px; font-weight: 400; text-align: left; line-height: 15px"> {{ scope.row.gradeStatusCodeName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="lastUpdateTimeValue" label="最后提交时间" width="160">
                        <template slot-scope="scope">
                            <span style="font-size: 14px; font-weight: 400; text-align: left; line-height: 15px"> {{ scope.row.lastUpdateTimeValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="address" label="操作" width="251">
                        <template slot-scope="scope">
                          <div style="display: inline-block;">
                            <div class="handle-btn" v-if="scope.row.isAllowSeePaper && scope.row.gradeStatusCodeName != '考试中'" @click="goAnswerPaper(scope.row)">查看答卷</div>
                            <div class="handle-btn" v-if="scope.row.isScoringAnalysis && scope.row.gradeStatusCodeName != '考试中'" @click="goScoringAnalysis(scope.row)">得分分析</div>
                          </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="position: absolute; bottom: 0; height: 30px; margin: 36px auto; display: flex; justify-content: center; align-items: center; width: 100%">
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from '@/components/nav.vue';
import Api from '@/api/examList';
export default {
    components: { Nav },
    data() {
        return {
            total: 0,
            page: 1,
            pageSize: 10,
            tableData: [],
            listQuery: {
                SkipCount: 0,
                MaxResultCount: 10,
                examUserListSearchBase: {
                    examClassCode: 'exam',
                    examName: '',
                },
            },
            activeType: 'make',
        };
    },
    methods: {
        goBack() {
            this.$router.back();
        },
        jscomNewOpenMaxWindow(url, target) {
            let tt, w, left, top, width, height;
            width = screen.width;
            height = screen.height - 60;
            left = 0;
            if (left < 0) {
                left = 0;
            }

            top = 0;
            if (top < 0) {
                top = 0;
            }

            tt = 'toolbar=no, menubar=no, scrollbars=yes,resizable=no,location=no, status=yes,fullscreen=yes,';
            tt = tt + 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top;
            w = window.open(url, target, tt);
            try {
                w.focus();
                return w;
            } catch (e) {}
        },
        goAnswerPaper(item) {
            if (item.isAllowSeePaper && item.gradeStatusCodeName != '考试中') {
                let routeData = this.$router.resolve({ path: '/exam/paper/detail', query: { examUid: item.examUid, examGradeUid: item.examGradeUid } });
                this.jscomNewOpenMaxWindow(routeData.href, '_blank');
                // this.$router.replace(`/exam/paper/detail?examUid=${item.examUid}&examGradeUid=${item.examGradeUid}`);
            }
        },
        goScoringAnalysis(item) {
            if (item.isScoringAnalysis && item.gradeStatusCodeName != '考试中') {
                let routeData = this.$router.resolve({ path: '/exam/grades/scoring-analysis', query: { examUid: item.examUid, examGradeUid: item.examGradeUid } });
                this.jscomNewOpenMaxWindow(routeData.href, '_blank');
                // this.$router.replace(`/exam/paper/detail?examUid=${item.examUid}&examGradeUid=${item.examGradeUid}`);
            }
        },
        searchList() {
            switch (this.activeType) {
                case 'make':
                    this.makeExam('make');
                    break;
                case 'makeUp':
                    this.makeExam('makeUp');
                    break;
                case 'noSubmit':
                    break;
            }
        },
        makeExam(type) {
            this.activeType = type;
            if (type == 'makeUp') {
                this.listQuery.examUserListSearchBase.isExamination = true;
            } else {
                this.listQuery.examUserListSearchBase.isExamination = false;
            }
            this.requestList();
            this.$forceUpdate();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.requestList();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.requestList();
        },
        requestList() {
            if (this.pageSize) {
                this.listQuery.MaxResultCount = this.pageSize;
            }
            this.listLoading = true;
            this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
            Api.getRecordList(this.listQuery)
                .then(res => {
                    this.tableData = res.data.items;
                    this.total = res.data.totalCount;
                    this.tableData.forEach(item => {
                        if (item.beginTimeFormat) {
                            item.beginTimeFormat = item.beginTimeFormat.split(' ')[0] + ' ' + item.beginTimeFormat.split(' ')[1].split(':')[0] + ':' + item.beginTimeFormat.split(' ')[1].split(':')[1];
                        }
                        if (item.endTimeFormat) {
                            item.endTimeFormat = item.endTimeFormat.split(' ')[0] + ' ' + item.endTimeFormat.split(' ')[1].split(':')[0] + ':' + item.endTimeFormat.split(' ')[1].split(':')[1];
                        }
                    });
                })
                .catch(err => {
                    console.warn(err);
                });
        },
    },
    created() {
        this.requestList();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
    height: 100%;
    border: none;
    padding-left: 32px;
}
.el-menu {
    background-color: initial;
}
.exam-list {
    // height: calc(100% - 100px);
    height: 100%;

    background: url('../../assets/exam/exam-bg.jpg');

    .list-header {
        height: 490px;
        background: url('../../assets/list/BG.png');

        .list-banner {
            height: 400px;
            position: relative;
            .exam-search {
                width: 1300px;
                margin: 0 auto;
                position: absolute;
                top: 70%;
                transform: translate(-50%, -50%);
                left: 50%;
                .search-header {
                    font-size: 56px;
                    // font-family: PingFang-SC-Bold, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #1e2046;
                    line-height: 64px;
                    margin-bottom: 64px;
                }
                .search-desc {
                    font-size: 16px;
                    // font-family: PingFang-SC-Bold, PingFangSC-Light;
                    font-weight: 300;
                    text-align: left;
                    color: #1e2046;
                    line-height: 66px;
                }
                .search-input-modal {
                    display: flex;
                    width: 650px;
                    height: 70px;
                    position: relative;
                    background: #ffffff;
                    border-radius: 15px;
                    overflow: hidden;
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
                    .search-btn {
                        width: 170px;
                        height: 100%;
                        z-index: 99;
                        position: absolute;
                        right: 0;
                        .btn-text {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                margin-left: 16px;
                                height: 28px;
                                font-size: 20px;
                                // font-family: PingFangSC, PingFangSC-Medium;
                                font-weight: 500;
                                text-align: left;
                                color: #ffffff;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
    .list-main {
        padding-top: 150px;
        width: 1300px;
        margin: 0 auto 20px;
        .exam-name {
            font-size: 14px;
            font-weight: 600;
            // font-family: PingFangSC, PingFangSC-Medium;
            text-align: left;
            color: #000000;
            line-height: 15px;
        }
        .handle-btn {
            width: 84px;
            cursor: pointer;
            height: 30px;
            border: 1px solid #979797;
            border-radius: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            //display: flex;
            display: inline-block;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-right: 3px;
            &:hover {
                background: #1fa3ff;
                color: #fff;
                border: none;
            }
        }
        .main-header {
            display: flex;
            margin-bottom: 18px;
            justify-content: space-between;
            align-items: center;
            .content-btn {
                width: 132px;
                height: 46px;
                border-radius: 25px;
                line-height: 46px;
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                background: #f5f5f7;
                color: #2d2d2d;
                margin-right: 35px;
                &.select {
                    background: rgba(31, 163, 255, 0.09);
                    color: #1fa3ff;
                }
            }
            .file-btn {
                width: 126px;
                height: 42px;
                // opacity: 0.47;
                border: 1px solid #cacaca;
                font-size: 16px;
                // font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                color: #000000;
                display: flex;
                align-items: center;
                cursor: pointer;
                justify-content: center;
            }
        }
        .main-content {
            // min-height: 275px;
            position: relative;
            height: 685px;
            background: linear-gradient(136deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.8) 100%);
            border-radius: 15px;
            box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);
            overflow-x: hidden;
        }
        .main-center-border {
            width: 1300px;
            height: 2px;
            opacity: 0.1;
            background: #3e4294;
            margin: 28px auto 0;
        }
        .main-copyright {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            // font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #a5a5a5;
            line-height: 22px;
        }
    }
}
</style>
<style>
/* .main-content .el-table th.is-leaf, */
.main-content .el-table td,
.main-content .el-table--border,
.main-content .el-table__body-wrapper {
    /* grey */
    border: none;
    /* cursor: pointer; */
}
.main-content .el-table::before {
    height: 0;
}
</style>